.grid h1 {
  font-family: 'Comfortaa', sans-serif;
  font-size: 42px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.grid-content {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.grid-element {
  margin: 0 40px 40px 0;
  background: #353535;
  max-height: 430px;
  animation: animateGrid 0.5s;
  overflow: hidden;
}

@keyframes animateGrid {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* For a 4-column grid */
.grid-element:nth-child(4n + 4) {
  margin-right: 0;
}

@media screen and (max-width: 720px) {
  .grid-content {
    grid-template-columns: auto auto;
  }

  .grid-element:nth-child(2n + 2) {
    margin-right: 0;
  }

  .grid h1 {
    font-size: 22px;
  }
}
