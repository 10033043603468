.loadmorebtn {
  background: #327ab7;
  width: 100%;
  min-height: 50px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}

.loadmorebtn p {
  font-family: 'Comfortaa', sans-serif;
  font-size: 42px;
  padding: 20px;
}

.loadmorebtn:hover {
  opacity: 0.8;
}
