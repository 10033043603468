.actor {
  box-sizing: border-box;
}

.actor img {
  width: 40%;
  height: auto;
  float: left;
  box-sizing: border-box;
}

.actor-name {
  font-family: 'Comfortaa', sans-serif;
  font-size: 22px;
  color: #fff;
  float: left;
  margin: 10px 20px;
  width: 40%;
  box-sizing: border-box;
}

.actor-character {
  font-family: 'Comfortaa', sans-serif;
  font-size: 18px;
  color: #fff;
  float: left;
  margin: 0 20px 10px 20px;
  width: 40%;
  box-sizing: border-box;
}
