.movieinfo {
  background-size: cover !important;
  background-position: center !important;
  width: 100%;
  height: 1000px;
  padding: 40px 20px;
  box-sizing: border-box;
  animation: animateMovieinfo 1s;
}

@keyframes animateMovieinfo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.movieinfo-content {
  max-width: 1280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgb(0, 0, 0, 0.7);
  position: relative;
}

.movieinfo-thumb {
  width: 500px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0px;
}

.movieinfo-text {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  width: auto;
  padding: 40px;
  color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  position: absolute;
  left: 500px;
}

.movieinfo-text h1 {
  font-family: 'Comfortaa', sans-serif;
  font-size: 48px;
  margin: 0;
}

.movieinfo-text h3 {
  font-size: 16px;
  line-height: 0;
  margin-top: 30px;
}

.movieinfo-text p {
  font-family: 'Comfortaa', sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.rating {
  width: 250px;
  height: 20px;
  margin-top: 20px;
  position: relative;
}

.score {
  position: absolute;
  margin: 0;
  right: 0px;
  top: -3px;
}

.director {
  margin: 0;
}

.fa-plus {
  position: absolute;
  bottom: 40px;
  right: 40px;
  color: #fff;
  border: solid 4px white;
  border-radius: 50px;
  padding: 3px 10px;
}

meter::-webkit-meter-bar {
  background: #fff;
  width: 200px;
}
meter::-webkit-meter-optimum-value {
  background: linear-gradient(to bottom, #16d47b);
}
meter::-webkit-meter-suboptimum-value {
  background: linear-gradient(to bottom, #fbb450);
}
meter::-webkit-meter-even-less-good-value {
  background: linear-gradient(to bottom, #ee5f5b);
}
